import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage7() {
  const question = {
    title: 'Frage 7 von 35',
    headline: '',
    subline: 'Ich mag es, wenn die Liegedauer meiner Patienten ...',
  }

  const answers = [
    {
      name: 'Liegedauer',
      id: 'tage',
      value: '... nur wenige Tage beträgt.',
      desc: '',
    },
    {
      name: 'Liegedauer',
      id: 'woche',
      value: '... in etwa eine Woche ist.',
      desc: '',
    },
    {
      name: 'Liegedauer',
      id: 'monat',
      value: '... bis zu einem Monat ist.',
      desc: '',
    },
    {
      name: 'Liegedauer',
      id: 'monate',
      value: '... einige Monate andauert.',
      desc: '',
    },
    {
      name: 'Liegedauer',
      id: 'gemischt',
      value: '... gemischt ist.',
      desc: '',
    },
    {
      name: 'Liegedauer',
      id: 'egal',
      value: 'Das ist mir egal.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-turkis-waves">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="turkis"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="yellow"
              index={7}
            />
          </div>
        </section>

        <MatchingNavigation
          color="turkis"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-8`}
          prev={`${AREA_CARE_PATH}/spielen/frage-6`}
          index={7}
        />

        <Icon position="3" name="calendar" />
      </main>
    </>
  )
}
